import React from 'react';
import { Link } from 'react-router-dom';
// Components
import WhatOnePageCanDo from './WhatOnePageCanDo';
import FreeSiteFaqComponent from '../faq/FreeSiteFaqComponent';
// Constants
import { CONTACT_PAGE_URL } from '../../utils/Constants';
import FeaturedProjectWebComponent from '../projects/FeaturedProjectWebComponent';
import HostingPricingGuide from '../pricing/HostingPricingGuide';

function OnePageOfferMainContainer() {
  return (
    <main role='main' className='min-h-screen' id='one_page_offer_main'>
      <div>
        <WhatOnePageCanDo />
      </div>
      <section className='grid justify-center'>
        <Link
          to={CONTACT_PAGE_URL}
          className='text-center py-2 px-1 bg-colour5 text-colour1 border-2 border-solid border-colour5 rounded-lg active:brightness-110 ease-in hover:brightness-110 shadow-cardShadow min-w-40'
        >
          Claim Now
        </Link>
      </section>
      <section className='container mx-auto mt-12 mb-20 px-8 lg:px-16'>
        <FeaturedProjectWebComponent />
      </section>
      <div>
        <HostingPricingGuide />
      </div>
      <div>
        <FreeSiteFaqComponent isCentered={false} />
      </div>
    </main>
  );
}

export default OnePageOfferMainContainer;
