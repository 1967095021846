import React from 'react';
import PricingContainer from './PricingContainer';
import PricingTable from './PricingTable';
import { DOMAIN_MANAGEMENT_PRICE_GBP } from '../../utils/Constants';

function HostingPricingGuide() {
  return (
    <section className='grid w-full h-full px-6'>
      <div>
        <PricingContainer />
      </div>
      <div>
        <PricingTable />
      </div>
      <section className='grid gap-2 container mx-auto px-6 lg:px-16 mt-8 lg:mt-12 lg:mb-12'>
        <article className='grid gap-2'>
          <p>
            Hosting prices vary depending on the <strong>size in MB</strong> of
            your site, as well as the number of people connecting at once.{' '}
          </p>
          <p>
            If your site starts to gain a large number of visitors or your
            requirements exceed the standard hosting package, you will need to
            upgrade to a custom hosting package to handle your requirements
          </p>
        </article>
        <section>
          <article>
            <p>
              <strong>Domain names</strong> are not included in the pricing
              guide.
            </p>
            <p>
              You can have us purchase and manage your domains names for you at
              a yearly fee of £{DOMAIN_MANAGEMENT_PRICE_GBP.toFixed(2)} per domain name.
            </p>
          </article>
        </section>
      </section>
    </section>
  );
}

export default HostingPricingGuide;
