import React, { useState } from 'react';
import { pricingPlansArray } from '../../utils/data/PricingData';
import SubHeaderComponent from '../headers/SubHeaderComponent';

function PricingContainer() {
  const [pricing] = useState(pricingPlansArray);

  return (
    <section className='relative z-10 overflow-hidden pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]'>
      <div className='container mx-auto lg:px-16'>
        <section className='grid px-6 mb-8 lg:mb-12'>
          <SubHeaderComponent
            title={
              <>
                Our offers applies to{' '}
                <span className='text-colour5'>various</span> of sites and
                features
              </>
            }
            subtitle={'Our Pricing Plan'}
            isCentered={true}
          />
        </section>

        <div className='-mx-4 flex flex-wrap justify-center'>
          <div className='-mx-4 flex flex-wrap'>
            {pricing.map((item, index) => (
              <PricingCard key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingContainer;

const PricingCard = ({ item, active }) => {
  return (
    <div className='w-full px-8 md:w-1/2 lg:w-1/3'>
      <div className='relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-8 py-10 shadow-cardShadow dark:border-dark-3 dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-[50px]'>
        <span className='mb-3 block text-lg lg:text-xl font-semibold text-colour5'>
          {item.type}
        </span>
        <h2 className='mb-5 text-[42px] font-bold text-gray-900 dark:text-white'>
          £{item.price}
          <span className='text-base font-medium text-body-color dark:text-dark-6'>
            / {item.subscription}
          </span>
        </h2>
        <p className='border-b-[1px] border-solid border-gray-300 pb-4 mb-4 text-base text-body-color dark:border-dark-3 dark:text-dark-6'>
          {item.description}
        </p>

        {/* List Section */}
        <div className='mb-9 flex flex-col gap-[14px]'>
          {item.features.map((child, index) => (
            <List key={index}>{child}</List>
          ))}
        </div>

        {/* Button */}
        <a
          href='/#'
          className={`block bg-colour5 w-full shadow-md rounded-md border border-stroke p-3 text-center text-base font-medium text-primary transition hover:border-primary hover:bg-primary text-white dark:border-dark-3`}
        >
          {item.buttonText}
        </a>

        {/* SVG Section */}
        <div>
          {/* Large Circle SVG */}
          <span className='absolute right-0 top-7 z-[-1]'>
            <CircleSvg />
          </span>

          {/* Dots SVG */}
          <span className='absolute right-4 top-4 z-[-1]'>
            <DotsSvg />
          </span>
        </div>
      </div>
    </div>
  );
};

const List = ({ children }) => {
  return (
    <p className='text-base text-body-color dark:text-dark-6'>{children}</p>
  );
};

// SVG Components for cleaner code
const CircleSvg = () => (
  <svg
    width={77}
    height={172}
    viewBox='0 0 77 172'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx={86} cy={86} r={86} fill='url(#paint0_linear)' />
    <defs>
      <linearGradient
        id='paint0_linear'
        x1={86}
        y1={0}
        x2={86}
        y2={172}
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#FB8500' stopOpacity='0.09' />
        <stop offset={1} stopColor='#C4C4C4' stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

const DotsSvg = () => (
  <svg
    width={41}
    height={89}
    viewBox='0 0 41 100'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    {[...Array(32)].map((_, index) => (
      <circle
        key={index}
        cx={(index % 4) * 12.5 + 1.42}
        cy={Math.floor(index / 4) * 12.5 + 1.42}
        r='1.82021'
        fill='#FB8500'
      />
    ))}
  </svg>
);
