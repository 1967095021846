import React from 'react';
// Constants
import {
  ANALYTICS_PRICE_GBP,
  BASIC_WEBSITE_PRICE_GBP,
  EMAIL_PRICE_GBP,
  FAST_BASIC_WEBSITE_PRICE_GBP,
  FAST_SHOP_PRICE_GBP,
  FAST_WEBSITE_SERVER_PRICE_GBP,
  SHOP_ANALYTICS_PRICE_GBP,
  SHOP_PRICE_GBP,
  WEBSITE_SERVER_PRICE_GBP,
} from '../../utils/Constants';

function PricingTable() {
  const tableData = [
    {
      rowLabel: 'Basic Hosting',
      basicSite: BASIC_WEBSITE_PRICE_GBP,
      severSite: WEBSITE_SERVER_PRICE_GBP,
      shopSite: SHOP_PRICE_GBP,
    },
    {
      rowLabel: 'Fast Hosting',
      basicSite: FAST_BASIC_WEBSITE_PRICE_GBP,
      severSite: FAST_WEBSITE_SERVER_PRICE_GBP,
      shopSite: FAST_SHOP_PRICE_GBP,
    },
    {
      rowLabel: 'Email',
      basicSite: EMAIL_PRICE_GBP,
      severSite: EMAIL_PRICE_GBP,
      shopSite: EMAIL_PRICE_GBP,
    },
    {
      rowLabel: 'Analytics',
      basicSite: ANALYTICS_PRICE_GBP,
      severSite: ANALYTICS_PRICE_GBP,
      shopSite: SHOP_ANALYTICS_PRICE_GBP,
    },
  ];

  const colLabels = ['Basic Site', 'Site + Server', 'Shops'];

  return (
    <section className='grid container mx-auto lg:px-16'>
      <table className='table-fixed border-collapse border bg-white rounded-lg shadow-cardShadow border-gray-300 w-full overflow-hidden'>
        <thead>
          <tr>
            <th className='border border-gray-300 p-2 bg-colour5 text-left'>Price Per Month</th>
            {colLabels.map((label, index) => (
              <th
                key={index}
                className='border border-gray-300 p-2 text-xs bg-blue-100'
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index} className=''>
              <td className='border-t-[1px] border-solid border-gray-300 p-2 bg-blue-100 text-xs'>
                {item.rowLabel}
              </td>
              <td className='border-t-[1px] border-solid border-gray-300 p-2 text-sm'>
                £{item.basicSite.toFixed(2)}
              </td>
              <td className='border-t-[1px] border-solid border-gray-300 p-2 text-sm'>
                £{item.severSite.toFixed(2)}
              </td>
              <td className='border-t-[1px] border-solid border-gray-300 p-2 text-sm'>
                £{item.shopSite.toFixed(2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

export default PricingTable;
