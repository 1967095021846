import React from 'react';
// Data
import { displayProjectsArray } from '../../utils/data/PortfolioDataUtils';
// Icons
import ArrowIcon from '../../assets/images/icons/curved_arrow_icon_show_more_indicator.svg';

function FeaturedProjectWebComponent() {
  return (
    <section className='relative grid h-full lg:grid-cols-3 gap-4'>
      {/* Arrow */}
      <div className='absolute -left-6 lg:-top-10 lg:-left-12'>
        <div className='max-w-16 leading-4 pb-1 font-semibold text-colour5 font-abril'>
          <span>Scroll over the image to see more</span>
        </div>
        <img
          src={ArrowIcon}
          alt='Arrow for scrolling indication'
          className='md:w-16 lg:w-20'
        />
      </div>

      {/* Items */}
      {displayProjectsArray.map((item) => {
        return (
          <article key={item.label} className='grid h-full py-1 px-4 w-full'>
            <div className='grid bg-colour1 max-h-96 w-full overflow-x-hidden scrollbar-hidden overflow-y-auto shadow-cardShadow rounded-br-lg rounded-bl-lg'>
              <div>
                <img src={item.mainImageSource} alt={item.label} loading='lazy' />
              </div>
              {/* <h6>{item.label}</h6> */}
              {/* <div className='sticky bottom-0 w-full bg-colour3 text-colour1 py-1'>
                <a href='/'>
                  <div className='text-center'>
                    <span>Visit Site</span>
                  </div>
                </a>
              </div> */}
            </div>
          </article>
        );
      })}
    </section>
  );
}

export default FeaturedProjectWebComponent;
