import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Components
import SubHeaderComponent from '../headers/SubHeaderComponent';
// Data
import { BASIC_WEBSITE_PRICE_GBP, FREE_PAGE_TERMS_URL } from '../../utils/Constants';

function FreeSiteFaqComponent({ isCentered }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const questionsAndAnswers = [
    {
      question: 'How long does it take to make a website?',
      answer: 'It typically takes 2-3 days to complete a basic website.',
    },
    {
      question: 'Do I need a domain?',
      answer:
        'Yes, you need a domain. If you don’t have one, we can purchase and manage it for you.',
    },
    {
      question: 'Where can I see the full terms and conditions?',
      answer: (
        <>
          You can view the full terms and conditions by clicking here:{' '}
          <Link
            className='text-hyperlink-blue hover:brightness-90'
            to={FREE_PAGE_TERMS_URL}
          >
            Full Terms and Conditions
          </Link>
          .
        </>
      ),
    },
    {
      question: 'What if I want small changes one day?',
      answer:
        'We offer updates to text and images as part of our standard service. For a full redesign, prices start at £100, or you may require a custom site.',
    },
    {
      question: 'How much does it cost?',
      answer:
        `Hosting starts at a super-affordable ${BASIC_WEBSITE_PRICE_GBP} per month, which can be paid monthly or yearly.`,
    },
    {
      question: 'Can I cancel at any time?',
      answer:
        'Yes, you can cancel anytime. However, if you’ve paid for the year upfront, refunds are not available.',
    },
    {
      question: 'Can I have a shop?',
      answer:
        'Yes, but you will be limited in amount of items you can sell. The hosting price for a shop, server and database starts at £28 a month. For complex store with multiple sections, pages or user reviews you will need to purchase a custom site.',
    },
    {
      question: 'Whats included in the special offer?',
      answer: (
        <>
          Multiple information pages, custom design, website management, free
          updates to contents. For more information see the pricing table
          <Link
            className='text-hyperlink-blue hover:brightness-90'
            to={FREE_PAGE_TERMS_URL}
          >
            here
          </Link>
          .
        </>
      ),
    },
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className='grid h-full w-full py-12'>
      <div className='grid grid-rows-reg gap-6 w-3/4 mx-auto'>
        <SubHeaderComponent
          isCentered={isCentered}
          title={'Free Website FAQ'}
          subtitle={'Your questions answered'}
        />

        <section className='grid'>
          {questionsAndAnswers.map((item, index) => (
            <section
              key={index}
              className='border-b-2 border-solid border-gray-300'
            >
              <button
                className='w-full flex justify-between items-center py-4 text-left text-lg font-medium text-gray-800 focus:outline-none'
                aria-label='toggle answer'
                onClick={() => toggleAnswer(index)}
              >
                <span>{item.question}</span>
                <span>{activeIndex === index ? '-' : '+'}</span>
              </button>
              {activeIndex === index && (
                <div className='px-4 pb-4 text-gray-600'>{item.answer}</div>
              )}
            </section>
          ))}
        </section>
      </div>
    </section>
  );
}

export default FreeSiteFaqComponent;
