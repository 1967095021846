import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
// Constants
import {
  ABOUT_PAGE_URL,
  BLOG_PAGE_URL,
  CONTACT_PAGE_URL,
  COOKIE_TIMER,
  CookiePolicyName,
  FREE_WEBSITE_PAGE_URL,
  HOME_PAGE_URL,
  INFO_PAGE_URL,
  MAINTENANCE_PAGE_URL,
  POLICIES_PAGE_URL,
  PORTFOLIO_PAGE_URL,
  PROJECT_PAGE_URL,
  SERVICES_PAGE_URL,
  SHOWCASE_PAGE_URL,
} from './utils/Constants';
// Components
import LoadingScreen from './components/utils/LoadingScreen';
// Normal import for HomePage (no lazy loading)
import HomePage from './pages/home/HomePage';
import AboutUsPage from './pages/about/AboutUsPage';
import ServicesPage from './pages/services/ServicesPage';
import ProjectPage from './pages/project/ProjectPage';
import BlogPage from './pages/blog/BlogPage';
import BlogPostPage from './pages/blog/BlogPostPage';
import InfoPage from './pages/info/InfoPage';
import ContactPage from './pages/contact/ContactPage';
import ShowcasePage from './pages/showcase/ShowcasePage';
import PortfolioPage from './pages/portfolio/PortfolioPage';
import FreeWebsiteOfferPage from './pages/offers/FreeWebsiteOfferPage';
// Lazy-loaded Pages
const MaintenancePage = lazy(() =>
  import('./pages/maintenance/MaintenancePage')
);
const Error404 = lazy(() => import('./pages/error/Error404'));
const TermAndPoliciesPage = lazy(() =>
  import('./pages/policies/TermAndPoliciesPage')
);

// Components
const CookieConsentModal = lazy(() =>
  import('./components/modals/CookieConsentModal')
);

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [hasAgreedToCookies, setHasAgreedToCookies] = useState(true);

  useEffect(() => {
    const cookie = localStorage.getItem(CookiePolicyName);

    if (cookie) {
      setHasAgreedToCookies(true);
    } else {
      setHasAgreedToCookies(false);
    }
  }, []);

  useEffect(() => {
    if (hasAgreedToCookies) {
      setIsVisible(false);
    }

    const timer = setTimeout(() => {
      if (!hasAgreedToCookies) {
        setIsVisible(true);
      }
    }, COOKIE_TIMER);

    return () => clearTimeout(timer);
  }, [hasAgreedToCookies]);
  return (
    <>
      {isVisible && (
        <Suspense>
          <CookieConsentModal setHasAgreedToCookies={setHasAgreedToCookies} />
        </Suspense>
      )}

      {/* Suspense component wraps the lazy-loaded components */}
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {/* Main page routes */}
          <Route path={HOME_PAGE_URL} index element={<HomePage />} />

          {/* Eager loaded */}
          <Route path={ABOUT_PAGE_URL} element={<AboutUsPage />} />
          <Route path={SERVICES_PAGE_URL} element={<ServicesPage />} />
          <Route path={CONTACT_PAGE_URL} element={<ContactPage />} />
          <Route path={SHOWCASE_PAGE_URL} element={<ShowcasePage />} />
          <Route path={PROJECT_PAGE_URL} element={<ProjectPage />} />
          <Route path={PORTFOLIO_PAGE_URL} element={<PortfolioPage />} />
          <Route path={POLICIES_PAGE_URL} element={<TermAndPoliciesPage />} />

          <Route
            path={FREE_WEBSITE_PAGE_URL}
            element={<FreeWebsiteOfferPage />}
          />

          <Route path={BLOG_PAGE_URL} element={<BlogPage />} />
          <Route path={`${BLOG_PAGE_URL}/:title`} element={<BlogPostPage />} />
          <Route path={INFO_PAGE_URL} element={<InfoPage />} />
          {/* Other */}
          <Route path={MAINTENANCE_PAGE_URL} element={<MaintenancePage />} />

          {/* Error routes */}
          <Route path='*' element={<Error404 />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
