import React from 'react';
import { Link } from 'react-router-dom';
// Constants
import {
  CompanyName,
  CONTACT_PAGE_URL,
  POLICIES_PAGE_URL,
} from '../../../utils/Constants';

function OfferHeader() {
  return (
    <header className='grid h-full w-full overflow-hidden'>
      <div className='grid container mx-auto lg:w-1/2'>
        <section className='grid items-center -mt-12 px-6'>
          <article className='grid gap-6 text-center'>
            <section>
              <div>
                <h1 className='text-colour3 text-3xl xs:text-4xl lg:text-5xl 2xl:text-6xl font-bold mb-4'>
                  <span className='text-colour5'>Free</span> Website Offer
                </h1>
              </div>
              <div>
                <h2>
                  {CompanyName} is offering free website design to market your brand!
                </h2>
              </div>
            </section>
            <section className='grid grid-cols-2 gap-2'>
              <Link
                to={CONTACT_PAGE_URL}
                className='text-center py-2 px-1 bg-colour5 text-colour1 border-2 border-solid border-colour5 rounded-lg active:brightness-110 ease-in hover:brightness-110 shadow-cardShadow'
              >
                Claim Now
              </Link>
              <a href={`#one_page_offer_main`} className='text-center py-2 px-1 bg-colour1 text-colour5 border-2 border-solid border-colour5 hover:brightness-110 shadow-cardShadow rounded-lg active:brightness-125 ease-in'>
                Learn More
              </a>
            </section>
            <section className='text-colour3 text-lg'>
              <p>
                A free website is a great way to test if a website is right for
                your business or personal needs. We create tailored simple and
                eligant sites and maintain them professionally with SEO (Search Engine Optimization) and media
                updates. You only pay for hosting and we take care of everything
                else. 
              </p>
              <p className='mt-2'>See more below</p>
            </section>
          </article>
          <section className='gird absolute bottom-2 transform -translate-x-1/2 left-1/2'>
            <div>
              <a href={`${POLICIES_PAGE_URL}#free-one-page-website-terms`}>
                <span className='text-colour3'>Read Full Terms</span>
              </a>
            </div>
          </section>
        </section>
      </div>
    </header>
  );
}

export default OfferHeader;
