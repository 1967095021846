export const emptyContactFormData = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  phoneNumber: '',
  location: '',
  businessName: '',
  projectType: '',
};

export const emptyCallbackFormData = {
  fullName: '',
  phoneNumber: '',
  projectType: 'none',
};
