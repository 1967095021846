// Constants 
import { BASIC_WEBSITE_PRICE_GBP, SHOP_PRICE_GBP, WEBSITE_SERVER_PRICE_GBP } from '../Constants';

export const pricingPlansArray = [
  {
    type: 'Basic Website',
    price: BASIC_WEBSITE_PRICE_GBP,
    subscription: 'month',
    description: 'Perfect for using in a personal website or business information sharing.',
    buttonText: 'Basic Website',
    features: [
      'Multiple Pages',
      'Contant Information',
      'Images and Galleries',
      'Videos',
    ],
  },
  {
    type: 'Website and Server',
    price: WEBSITE_SERVER_PRICE_GBP,
    subscription: 'month',
    description: 'For interactive user content and forms.',
    buttonText: 'Server',
    active: true,
    features: [
      'Backend Server',
      'Database Server',
      'Send and Receive Data',
      'Sell Limited Number of Items'
    ],
  },
  {
    type: 'Shops',
    price: SHOP_PRICE_GBP,
    subscription: 'month',
    description: 'Sell your items with a full shop',
    buttonText: 'Shop',
    features: [
      'Payment Processing',
      'Security',
      'Admin Page',
      'Analytics',
    ],
  },
];
